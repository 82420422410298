/*MessageBox.js*/
.messagebox { /*Main overarching div*/
    text-align: center;
}

header { /*Header for "Jinja Ninja" div*/
    border-bottom: 2px solid black;
}

header h1 {
    color: rgb(151, 52, 132)
}

.split {
    width: 50%;
    position: static;
    display: inline-block;
}

.left {
    float: left;
    margin-top: 5px;
    margin-right: 5px;
}

.right {
    float: right;
    margin-top: -36px;
}

.main { /*First Select Tags*/
    width: 578px;
    display: inline-block;
}

.json_data { /*Second Div for Second Select Tag*/
    display: inline-block;
}

.json_data-button { /*Second Select Tag Button*/
    float: left;
    width: 20px;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 2px;
}

.json_data-select { /*Second Select Tag*/
    float: right;
    width: 578px;
}

.message_body {
    min-width: 600px;
    min-height: 330px;
    max-width: 600px;
    max-height: 330px;
}

.jn-logo {
  max-width: 300px;
}
